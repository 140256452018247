<template>
    <div class="payment-card" :key="cache">
        <v-row class="d-flex align-center justify-center text-center ma-0" v-for="payment in subscriptions" :key="payment.id">
            <v-col cols="4" md="1" class="my-auto" v-show="$vuetify.breakpoint.mdAndUp">
                <v-icon v-if="checkExpiry(payment.expiryDate)" color="success">fa-check-circle</v-icon>
                <v-icon v-else color="error">fa-times-circle</v-icon>
            </v-col>
            <v-col class="my-auto d-flex flex-column" cols="12" md="4">
                <!-- <strong class="ma-0 text-capitalize" v-if="payment.amount">${{ (payment.amount / 100).toFixed(2) }} </strong> -->
                <p class="pa-1 ma-1">
                    <small class="text-center pa-1 ma-1">Subscription</small>
                </p>
                <p class="pa-1 ma-1">
                    <strong class="ma-0 text-capitalize">{{ payment.title }}</strong>
                </p>
                <v-divider v-show="$vuetify.breakpoint.smAndDown"></v-divider>
            </v-col>
            <!-- <v-col class=" my-auto" cols="5" md="3">
        <p class="ma-0 text-capitalize">{{ payment.title }}</p>
      </v-col> -->
            <v-col class="my-auto d-flex flex-column" cols="12" md="3">
                <!-- <p class="ma-0">{{ new Date(payment.expiryDate).toDateString() }}</p>
          <small class="ma-0">{{ payment._id }}</small> -->
                <small class="text-center pa-1 ma-1">Expiry Date</small>
                <p class="text-center pa-1 ma-1">
                    {{ payment.expiryDate ? new Date(payment.expiryDate).toDateString() : "-" }}
                </p>
                <v-divider v-show="$vuetify.breakpoint.smAndDown"></v-divider>
            </v-col>
            <v-col class="my-auto d-flex flex-column" cols="12" md="3">
                <small class="text-center pa-1 ma-1">Auto-Renwal Status</small>
                <p class="text-center pa-1 ma-1">{{ payment.renew ? "On" : "Off" }}</p>
            </v-col>
        </v-row>

        <!-- <template v-if="canceled">
      <v-divider> </v-divider>
      <div class="text-center pa-4 mb-2">Your subscription has been canceled</div>
      <v-divider> </v-divider>
    </template> -->

        <v-row v-if="currentSubscriptionRenew === false && isSubscription" class="d-flex text-center mx-auto">
            <v-col class="pa-0">
                <v-btn block @click="toggleRenewalStatus()" :loading="loading" class="cancel-btn"> Enable Auto-Renwal </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="currentSubscriptionRenew === true && isSubscription" class="d-flex text-center mx-auto">
            <v-col class="pa-0">
                <v-btn block @click="openCancelDialog()" :loading="loading" class="cancel-btn"> Cancel Subscription </v-btn>
            </v-col>
        </v-row>

        <cancel-subscription-dialog
            ref="cancelDialog"
            @confirm="toggleRenewalStatus"
            :title="'Cancel Subscription'"
        ></cancel-subscription-dialog>
    </div>
</template>

<script>
import CancelSubscriptionDialog from "@/components/account/cancel-subscription-dialog";
import moment from "moment";
export default {
    components: {
        CancelSubscriptionDialog,
    },
    props: ["subscriptions"],

    data() {
        return {
            cache: 0,
            loading: false,
            canceled: false,
        };
    },
    computed: {
        currentSubscription() {
            return this.subscriptions.at(-1);
        },
        currentSubscriptionRenew() {
            console.log("Current re-newal status:", this.currentSubscription.renew);
            return this.currentSubscription.renew;
        },
        isSubscription() {
            if (this.currentSubscription.product == "64262004c18abe7229db9f4c") return false;
            else if (
                this.currentSubscription.product == "641a75c582c6a4039eb7081b" ||
                this.currentSubscription.product == "641a756e82c6a4039eb70811"
            ) {
                return true;
            } else return false;
        },
    },
    methods: {
        async toggleRenewalStatus() {
            this.loading = true;
            this.$refs.cancelDialog.loading = true;

            //get the last element to cancel
            const recent = this.subscriptions.at(-1);

            let renewStatus = recent.renew;
            console.log("Current renewal status", renewStatus);

            if (recent?.expires) {
                // console.log("Renew change to:", !renewStatus);
                const payload = { renew: !renewStatus };
                const response = await this.$fluro.api
                    .put(`/content/purchase/${recent._id}`, payload)
                    .then(({ data }) => {
                        this.canceled = true;

                        return data;
                    })
                    .catch((err) => console.log(err));

                console.log("RESPONSE", response);
                this.$emit("updateSubscription", response);
            }

            this.loading = false;
            this.$refs.cancelDialog.close();

            console.warn("Subscriptsion after renewal update:", this.subscriptions);
            // this.$refs.cancelDialog.loading = true;
        },

        openCancelDialog() {
            let recentPayment = this.subscriptions[this.subscriptions?.length - 1];
            this.$refs.cancelDialog.open(
                recentPayment,
                `Do you wish to cancel your current subscription?<br/> <strong class='ma-0'>${recentPayment.title}</strong>
        <br/>
        This will turn off auto-renwal.
        <br/>
        You can continue to use premium content until your current subscription expires.
        `
            );
        },
        // async cancelSubscription(purchaseId) {
        //   this.$refs.cancelDialog.loading = true;
        //   const response = await this.$fluro.api
        //     .delete(`/content/purchase/${purchaseId}`)
        //     .then(({ data }) => data)
        //     .catch((err) => console.log(err));

        //   console.warn("AFTER DELETE:", response);

        //   this.$refs.cancelDialog.close();
        // },
        checkExpiry(expiry) {
            console.log(this.subscriptions);
            const now = new Date();
            const expiryDate = new Date(expiry);
            if (expiryDate > now) return true;
            else return false;
        },

        mounted() {
            console.warn("Payment History!!!!!!!!!!!!:", this.subscriptions);

            console.log("Current Subscription:", this.currentSubscription);
        },
    },
};
</script>

<style scoped>
.width-full {
    width: 100%;
}

.payment-card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 25px;
}

.v-btn.cancel-btn {
    border-radius: 0 0 20px 20px;
    color: red;
}
</style>

<template>
  <v-dialog v-model="display" width="500" overlay-opacity="0.2" content-class="rounded-lg" rounded="lg" persistent>
    <v-card>
      <v-card-title class="text-h5 align-center justify-center px-5 primary--text" v-text="title"></v-card-title>
      <v-card-text class="pa-5 text-center text-body-2 primary--text" v-html="dialogMessage"></v-card-text>
      <v-card-actions class="pa-5">
        <v-btn
          v-if="!this.showError && !this.hideCancel"
          :disabled="!isValid"
          :color="cancelColor"
          class="full-width flex-shrink-1 flexk-grow-1"
          depressed
          outlined
          v-text="cancelLabel"
          @click="cancel"
        />
        <v-btn
          v-if="!this.showError && !this.hideAction"
          :disabled="!isValid"
          :color="actionColor"
          class="full-width flex-shrink-1 flexk-grow-1"
          depressed
          v-text="actionLabel"
          @click="confirm"
        />
        <v-btn v-if="this.showError" :color="actionColor" depressed block v-text="'Close'" @click="cancel" />
      </v-card-actions>

      <v-overlay color="white" opacity="0.85" class="p-absolute" v-if="loading">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    title: {
      type: String,
      default() {
        return "Alert";
      },
    },
    actionLabel: {
      type: String,
      default() {
        return "OK";
      },
    },
    cancelLabel: {
      type: String,
      default() {
        return "Cancel";
      },
    },
    actionColor: {
      type: String,
      default() {
        return "primary";
      },
    },
    cancelColor: {
      type: String,
      default() {
        return "primary";
      },
    },
    hideAction: Boolean,
    hideCancel: Boolean,
  },
  data() {
    return {
      display: false,
      loading: false,
      showError: false,
      model: null,
      message: "",
      errorMessage: "",
    };
  },
  computed: {
    isValid() {
      return !this.loading;
    },
    dialogMessage() {
      if (this.showError) return this.errorMessage || "<p>An error occured. Try again.</p>";
      return this.message || "<p>This is an alert.</p>";
    },
  },
  methods: {
    init(model) {
      this.showError = false;
      this.loading = false;

      if (!model) {
        this.model = null;
      } else {
        let clonedModel = _.cloneDeep(model);
        this.model = clonedModel;
      }
    },
    open(model, message) {
      this.init(model);
      this.message = message;
      this.display = true;
    },
    error(message) {
      this.errorMessage = message;
      this.showError = true;
      this.loading = false;
    },
    confirm() {
      this.loading = true;
      this.$emit("confirm", this.model);
    },
    cancel() {
      this.close();
    },
    close() {
      this.display = false;
    },
  },
};
</script>

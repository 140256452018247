<template>
  <div class="payment-card">
    <v-row class="d-flex text-center ma-0" v-for="payment in transactions" :key="payment.id">
      <v-col cols="1" md="1" class="my-auto">
        <v-icon v-if="checkExpiry(payment.expiryDate)" color="success">fa-check-circle</v-icon>
        <v-icon v-else color="error">fa-times-circle</v-icon>
      </v-col>
      <v-col class=" my-auto" cols="5" md="3">
        <strong class="ma-0 text-capitalize" v-if="payment.amount">${{ (payment.amount / 100).toFixed(2) }} </strong
        ><strong class="ma-0 text-capitalize">Card Payment</strong>
      </v-col>
      <v-col class=" my-auto" cols="5" md="4">
        <p class="ma-0">{{ payment.title }}</p>
      </v-col>
      <v-col class=" my-auto" cols="12" md="4">
        <div>
          <!-- <p class="ma-0">{{ new Date(payment.created).toDateString() }}</p>
          <small class="ma-0">{{ payment._id }}</small> -->
          <p class="ma-0">Expiry Date</p>
          <small class="ma-0">{{ new Date(payment.created).toDateString() }}</small>
          <v-divider v-show="$vuetify.breakpoint.smAndDown"></v-divider>
        </div>
      </v-col>
    </v-row>
    <template v-if="canceled">
      <v-divider> </v-divider>
      <p class="text-center pa-1 ma-1">Your subscription has been canceled</p>
      <v-divider> </v-divider>
    </template>

    <!-- <v-btn
         block
         @click="cancelSubscription()"
         :loading="loading"
         rounded
         :class="`mx-auto black--text active-product`"
      >
         Cancel Subscription
      </v-btn> -->
  </div>
</template>

<script>
export default {
  props: ["transactions"],

  data() {
    return {
      loading: false,
      canceled: false,
    };
  },
  computed: {
    currentSubscription() {
      return this.subscriptions.at(-1);
    },
    currentSubscriptionRenew() {
      console.log("Current re-newal status:", this.currentSubscription.renew);
      return this.currentSubscription.renew;
    },
    isSubscription() {
      if (this.currentSubscription.product == "64262004c18abe7229db9f4c") return false;
      else if (this.currentSubscription.product.includes(["641a75c582c6a4039eb7081b", "641a756e82c6a4039eb70811"])) {
        return true;
      } else return false;
    },
  },
  methods: {
    async cancelSubscription() {
      this.loading = true;
      //get the last element to cancel

      const payload = { renew: false };
      const response = await this.$fluro.api
        .put(`/content/purchase/${this.currentSubscription._id}`, payload)
        .then(({ data }) => {
          this.canceled = true;
          return data;
        })
        .catch((err) => console.log(err));
      console.log(response);

      this.loading = false;
    },
    checkExpiry(expiry) {
      const now = new Date();
      const expiryDate = new Date(expiry);
      if (expiryDate > now) return true;
      else return false;
    },
    checkFreeTrial(purchase) {
      //Purchase product must be one of the following,
      //7-day Free Trial: 64262004c18abe7229db9f4c
      //Annual Subscription: 641a756e82c6a4039eb70811
      //Monthly Subscription: 641a75c582c6a4039eb7081b
    },
  },
};
</script>

<style scoped>
.width-full {
  width: 100%;
}

.payment-card {
  width: 100%;
  background-color: #ffffff;
  border-radius: 25px;
}
</style>
